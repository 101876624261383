import keycloak from "core/keycloak";
import Cookies from "cookies";
var keyclockSettings = {
  checkLoginIframe: false
};
keycloak.init(keyclockSettings).then(onKeycloakReturned)["catch"](onKeycloakError);
function onKeycloakReturned(authenticated) {
  if (!authenticated) {
    redirectToKeycloak();
    return;
  }
  redirectToGame();
}
function redirectToKeycloak() {
  keycloak.login({
    redirectUri: "http://localhost:8080/"
  });
}
function redirectToGame() {
  Cookies.set("linguo.token", keycloak.token);
  window.location = "http://localhost:8080/game";
}
function onKeycloakError(err) {
  console.error("keycloak-error", err);
}